header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__contianer {
  text-align: center;
  height: 100%;
  position: relative;
}

/*===================CTA=================*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*===================Socials=================*/

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/*===================ME====================*/

.me {
  width: 22rem;
  height: 25rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 1rem;
  border-radius: 16rem 16rem 0 0;
  overflow: hidden;
  padding: 0 0 0 0;
}

/*===================Scroll Down===================*/
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-size: 0.9rem;
}

/*===============Media Queries (Medium Devices) ===================*/
@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
}

/*===============Small Queries (Medium Devices) ===================*/
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
}
