.portfolio__container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem;
  padding: 0 25%;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  text-align: center;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  max-height: 35rem;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
  font-size: 1rem;
}

.portfolio__item-cta {
  display: flex;
  margin: 0 auto;
  gap: 1rem;
}

/*===============Media Queries (Medium Devices) ===================*/
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr;
  }
  .portfolio__container > div {
    width: 80%;
    padding: 1rem;
    margin: 0 auto;
  }

  .portfolio__content {
    padding: 1rem;
    row-gap: 0.5rem;
  }

  .portfolio__item-image {
    max-height: fit-content;
  }
}

/*===============Small Queries (Medium Devices) ===================*/
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
  }
}
